@font-face {
  font-family: "Retro Gaming";
  src: url("./font/Retro\ Gaming.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Upheaval";
  src: url("./font/upheavtt.ttf");
  font-style: normal;
}

::selection {
  background-color: #000;
  color: #b4f8c8;
}


body {
  margin: 0 auto !important;
  font-family: "Retro Computer", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "brln",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
  overflow-x: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-width: 1400px;
}

code {
  font-family: "Retro Gaming", sans-serif;
}

:root {
  --main-color: #6891ed;
  --text-color: #e7e7e7;
  --mint: #B4F8C8;
  --black: #000;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
  padding: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Upheaval", "Upheaval", sans-serif !important;
}

p {
  font-family: "Retro Gaming" !important;
  letter-spacing: 0px;
}

.small-font {
  font-size: 16px !important;
}

/* .active { */
  /* background: var(--main-color); */
  /* border-radius: 8px; */
/* } */ 
.middeling {
  display: flex;
  justify-content: center;
  align-items: center;
}
hr {
  margin: 25px 0;
  color: var(--text-color) !important;
}
.link {
  color: var(--main-color);
}
.link:hover {
  color: var(--text-color);
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

button#wallet-connect-trustwallet,
button#wallet-connect-mathwallet,
button#wallet-connect-tokenpocket,
button#wallet-connect-binance\ chain\ wallet {
  display: none !important;
}


.wallet_button.not-connected,
.wallet_button.connected {
  font-size: 20px !important;
}

.modal-header .btn-close {
  color: #fff !important;
}

@media only screen and (max-width: 768px) {
  textarea,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  .input-checkbox,
  select,
  option {
    -webkit-appearance: none;
  } 
}